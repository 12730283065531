// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';

import './auth.scss';

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4" className="login_typo">
          Login
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2" className="sign_in_typo">
            Sign in on the internal platform
          </Typography>
        </Stack>
      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  );
}
