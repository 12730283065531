/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDinnerAccountGeneral } from 'src/@types/dinner';

import axios from '../../../utils/axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

interface rateType {
  userRate: any;
  reviews:any;
  review:any;
  loading: boolean;
  isFetchStudentsLoading: 'idle' | 'loading' | 'success' | 'failed';
  error: string;
  meta: object | null;
}

const initialState: rateType = {
    userRate: {
  




  },

  isFetchStudentsLoading: 'idle',

  meta: null,
  reviews: [],
  review:[],
  loading: false,
  error: '',
};


// eslint-disable-next-line consistent-return
export const fetchRating: any = createAsyncThunk('rate/fetchRating', async (params) => {
  
  
  try {
    const response = await axios.get(`${baseUrl}/rate`, {
      params,
    });
    return response.data.data;
  } catch (error) {
    return error;
  }
});

// eslint-disable-next-line consistent-return
export const getRatingById: any = createAsyncThunk(
  'rate/getRateById',
  // eslint-disable-next-line consistent-return
  async ({ _id }: any) => {
    try {
      const response = await axios.get(`${baseUrl}/rating/${_id}`);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const fetchUserRating: any = createAsyncThunk(
    'rate/getreviewByUserId',
    // eslint-disable-next-line consistent-return
    async ({id}: any) => {
      try {
        const response = await axios.get(`${baseUrl}/rate/${id}`);
   
        
        return response?.data;
      } catch (error) {
        return error;
      }
    }
  );
  














// eslint-disable-next-line consistent-return


export const reviewListSlice: any = createSlice({
  name: 'Review',
  initialState,
  reducers: {},
  extraReducers: {

    // --------- //

    // --------------- //

    
    // -------------- //

    [fetchRating.pending]: (state) => {
      state.loading = true;
    },

    [fetchRating.fulfilled]: (state, { payload }) => {
      state.loading = false;


      state.reviews = payload?.docs
      ;
      state.meta = payload.meta;

     
    },

    [fetchRating.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ---------------- //

    [getRatingById.pending]: (state) => {
      state.loading = true;
    },

    [getRatingById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.review = payload;
    },

    [getRatingById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ----------------- //

    [fetchUserRating.pending]: (state) => {
      state.loading = true;
    },

    [fetchUserRating.fulfilled]: (state, { payload }) => {
      state.loading = false;
    
      
      state.userRate = payload;
      state.meta = payload.meta;
    },

    [fetchUserRating.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ----------------- //

   

    // -------------------- //

   
    // -------------------- //



    // -------------------- //

   

    // ----------------- //

  
    // ----------------- //


  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = reviewListSlice.actions;

export default reviewListSlice.reducer;
