import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/NewPasswordPage')));


export const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));

export const Admin = Loadable(lazy(() => import('../pages/Admin')));
export const AdminDetails = Loadable(lazy(() => import('../pages/AdminDetailsPage')));
export const UpdateProfile = Loadable(lazy(() => import('../pages/AdminUpdatePage')));
export const NewAdmin = Loadable(lazy(() => import('../pages/AdminCreatePage')));
export const EditAdmin = Loadable(lazy(() => import('../pages/AdminEditPage')));
export const UpdateAdmin = Loadable(lazy(() => import('../pages/AdminUpdatePage')));
export const NewUser = Loadable(lazy(() => import('../pages/UserCreatePage')));
export const EditUser = Loadable(lazy(() => import('../pages/UserEditPage')));
export const Dinner = Loadable(lazy(() => import('../pages/Dinner')));
export const SideDishe = Loadable(lazy(() => import('../pages/SideDishe')));

export const SideDisheDetails = Loadable(lazy(() => import('../pages/SideDisheDetailsPage')));
export const NewSideDishe = Loadable(lazy(() => import('../pages/SideDisheCreatePage')));
export const EditSideDishe = Loadable(lazy(() => import('../pages/SideDisheEditPage')));

export const DinnerDetails = Loadable(lazy(() => import('../pages/DinnerDetailsPage')));
export const NewDinner = Loadable(lazy(() => import('../pages/DinnerCreatePage')));
export const EditDinner = Loadable(lazy(() => import('../pages/DinnerEditPage')));

export const DinnerScheduhle = Loadable(lazy(() => import('../pages/DinnerSchedule')));

export const Rating = Loadable(lazy(() => import('../pages/Ratings')));
export const Reservations = Loadable(lazy(() => import('../pages/Reservation')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

