/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDinnerAccountGeneral } from 'src/@types/dinner';

import axios from '../../../utils/axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

interface UserType {
  dinner: IDinnerAccountGeneral;

  dinners: IDinnerAccountGeneral[];
  loading: boolean;
  isFetchStudentsLoading: 'idle' | 'loading' | 'success' | 'failed';
  error: string;
  meta: object | null;
  nestedMainDishe:  IDinnerAccountGeneral[];
  hasMore:boolean

}

const initialState: UserType = {
  dinner: {
    name: '',

description:''




  },
  nestedMainDishe: [],

  isFetchStudentsLoading: 'idle',

  meta: null,
  hasMore:true,
  dinners: [],
  loading: false,
  error: '',
};


// eslint-disable-next-line consistent-return
export const fetchDinner: any = createAsyncThunk('dinner/fetchDinner', async (params) => {
  try {
    const response = await axios.get(`${baseUrl}/mainDishe`, {
      params,
    });
    return response?.data;
  } catch (error) {
    return error;
  }
});

// eslint-disable-next-line consistent-return
export const getDinnerById: any = createAsyncThunk(
  'dinner/getDinnerById',
  // eslint-disable-next-line consistent-return
  async ({ _id }: any) => {
    try {
      const response = await axios.get(`${baseUrl}/mainDishe/${_id}`);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);



export const createNewDinner: any = createAsyncThunk(
  'dinner/createDinner',
  // eslint-disable-next-line consistent-return
  async (data: IDinnerAccountGeneral) => {
    try {
      const response = await axios.post(`${baseUrl}/mainDishe`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const updateDinner: any = createAsyncThunk(
  'dinner/updateDinner',
  // eslint-disable-next-line consistent-return
  async ({ id, data }: { id: string; data: IDinnerAccountGeneral }, { rejectWithValue }) => {
  
    try {
      const response = await axios.put(
        `${baseUrl}/mainDishe/${id}`,
        { ...data },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
   

      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);










// eslint-disable-next-line consistent-return
export const deleteDinner: any = createAsyncThunk('users/deleteAdmin', async (_id) => {
  try {
    const response = await axios.delete(`${baseUrl}/mainDishe/${_id}`);
    return response.data;
  } catch (error) {
    return error;
  }
});

export const dinnerListSlice: any = createSlice({
  name: 'Dinner',
  initialState,
  reducers: {},
  extraReducers: {

    // --------- //

    // --------------- //

    
    // -------------- //

    [fetchDinner.pending]: (state) => {
      state.loading = true;
    },

    [fetchDinner.fulfilled]: (state, { payload }) => {
      state.loading = false;

      state.dinners = payload.data?.docs;
      state.meta = payload.data.totalPages;
      state.hasMore=payload.data.hasMore;
      
      if (
        payload?.data?.totalDocs !== undefined &&
        state?.nestedMainDishe?.length !== undefined &&
        payload.data.totalDocs > state.nestedMainDishe.length
      ) {
        state.nestedMainDishe = [...state.nestedMainDishe, ...payload.data.docs];
      }
      
      
    },

    [fetchDinner.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ---------------- //

    [getDinnerById.pending]: (state) => {
      state.loading = true;
    },

    [getDinnerById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dinner = payload.data;
     
    },

    [getDinnerById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ----------------- //

    [createNewDinner.pending]: (state) => {
      state.loading = true;
    },

    [createNewDinner.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dinner = payload.data;
    },

    [createNewDinner.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ----------------- //

   

    // -------------------- //

    [updateDinner.pending]: (state) => {
      state.loading = true;
    },

    [updateDinner.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dinner = payload.data;
    },

    [updateDinner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      
    },

    // -------------------- //



    // -------------------- //

   

    // ----------------- //

    [deleteDinner.pending]: (state) => {
      state.loading = true;
    },

    [deleteDinner.fulfilled]: (state) => {
      state.loading = false;

    },

    [deleteDinner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // ----------------- //


  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = dinnerListSlice.actions;

export default dinnerListSlice.reducer;
