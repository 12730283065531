/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IUserAccountGeneral } from 'src/@types/user';
import axios from '../../../utils/axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

interface UserType {
  user: IUserAccountGeneral;
  usersList: IUserAccountGeneral[];
  adminsList: IUserAccountGeneral[];
  loading: boolean;
  isFetchStudentsLoading: 'idle' | 'loading' | 'success' | 'failed';
  error: string;
  meta: object | null;
  Roles:any
}

const initialState: UserType = {
  user: {
    name: '',
    lastname: '',
    email: '',
user:{},
    phone: '',
    type: '',
    image: '',
  
  },
  Roles:"",
  isFetchStudentsLoading: 'idle',

  meta: null,
  usersList: [],
  adminsList: [],
  loading: false,
  error: '',
};

// eslint-disable-next-line consistent-return
export const fetchUsers: any = createAsyncThunk(
  'type/fetchUsers',
  async (payload: {  params?: any }) => {
    try {
      const response = await axios.get(`${baseUrl}/users`, {
        params: payload?.params,
      });

      return response?.data?.data;
    } catch (error) {
      return error;
    }
  }
);

// eslint-disable-next-line consistent-return
export const fetchAdmins: any = createAsyncThunk('type/fetchAdmins', async (params) => {
  try {
    const response = await axios.get(`${baseUrl}/user`, {
      params,
    });
  
    return response?.data;
  } catch (error) {
    return error;
  }
});

// eslint-disable-next-line consistent-return
export const getUserById: any = createAsyncThunk(
  'users/getUserById',
  // eslint-disable-next-line consistent-return
  async ({_id }: any) => {
    try {
      const response = await axios.get(`${baseUrl}/user/${_id}`);
      
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getRoles: any = createAsyncThunk(
  'users/getUserById',
  // eslint-disable-next-line consistent-return
  async () => {
    try {
      const response = await axios.get(`${baseUrl}/role`);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const createNewUser: any = createAsyncThunk(
  'users/createUser',
  // eslint-disable-next-line consistent-return
  async (data: IUserAccountGeneral) => {
    try {
      const response = await axios.post(
        `${baseUrl}/user`,
        { ...data },
       
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const createNewAdmin: any = createAsyncThunk(
  'users/createAdmin',
  // eslint-disable-next-line consistent-return
  async (data: IUserAccountGeneral) => {
    try {
      const response = await axios.post(`${baseUrl}/user`, data, {
      
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const updateUser: any = createAsyncThunk(
  'users/updateUser',
  // eslint-disable-next-line consistent-return
  async ({ id, data }: { id: string; data: IUserAccountGeneral }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/user/${id}`,
        { ...data },
      
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);



export const EditPasswordUserAdmin: any = createAsyncThunk(
  'admin/edit userPassword',
  // eslint-disable-next-line consistent-return
  async ({ adminId, password, passwordConfirm }: any) => {
    try {
      const response = await axios.put(`${baseUrl}/profile/admin/${adminId}/password`, {
        password,
        passwordConfirm,
      });

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateAdminPassword: any = createAsyncThunk(
  'admin/updatePassword',
  // eslint-disable-next-line consistent-return
  async (data: any) => {
    try {
      const response = await axios.put(`${baseUrl}/profile/adminPassword/`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const updateMyAdmin: any = createAsyncThunk(
  'admin/updateMyAdmin',
  // eslint-disable-next-line consistent-return
  async (data: IUserAccountGeneral) => {
    const newData: any = {
      name: data.name,
      lastname: data.lastname,
      gender: data.gender,
    };

    if (data.phone !== '') newData.phone = data.phone;
    if (data.image !== null) newData.profilePicUrl = data.image;

    try {
      const response = await axios.put(`${baseUrl}/profile/admin/`, newData, {
        headers: {
          'Content-Type': 'multipart/raw',
        },
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }
);

// eslint-disable-next-line consistent-return
export const deleteUser: any = createAsyncThunk('users/deleteUser', async (_id) => {
  try {
    const response = await axios.delete(`${baseUrl}/user/${_id}`);
    return response.data;
  } catch (error) {
    return error;
  }
});

// eslint-disable-next-line consistent-return
export const deleteAdmin: any = createAsyncThunk('users/deleteAdmin', async (_id) => {
  try {
    const response = await axios.delete(`${baseUrl}/user/${_id}`);
  
    return response.data.data;

    
  } catch (error) {
    return error;
  }
});

export const usersListSlice: any = createSlice({
  name: 'Users',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.isFetchStudentsLoading = 'loading';
    },

    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.isFetchStudentsLoading = 'success';
      state.usersList = payload.data.docs;
      state.meta = payload.data.meta;
    },

    [fetchUsers.rejected]: (state, { error }) => {
      state.isFetchStudentsLoading = 'failed';
      state.error = error;
    },
    // --------- //
    [updateMyAdmin.pending]: (state) => {
      state.loading = true;
    },

    [updateMyAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },

    [updateMyAdmin.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
    // --------------- //

    [updateAdminPassword.pending]: (state) => {
      state.loading = true;
    },

    [updateAdminPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },

    [updateAdminPassword.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
    // -------------- //

    [fetchAdmins.pending]: (state) => {
      state.loading = true;
    },

    [fetchAdmins.fulfilled]: (state, { payload }) => {
      state.loading = false;
      
      state.adminsList = payload?.data?.docs;
      state.meta = payload?.data?.meta;
    },

    [fetchAdmins.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ---------------- //

    [getUserById.pending]: (state) => {
      state.loading = true;
    },

    [getUserById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload.data;
    },

    [getUserById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ----------------- //

    [createNewUser.pending]: (state) => {
      state.loading = true;
    },

    [createNewUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload.data;
    },

    [createNewUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ----------------- //

    [createNewAdmin.pending]: (state) => {
      state.loading = true;
    },

    [createNewAdmin.fulfilled]: (state) => {
      state.loading = false;
    },

    [createNewAdmin.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // -------------------- //

    [updateUser.pending]: (state) => {
      state.loading = true;
    },

    [updateUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload.data;
    },

    [updateUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getRoles.pending]: (state) => {
      state.loading = true;
    },

    [getRoles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.Roles = payload.data;
    },

    [getRoles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // -------------------- //

   

    // -------------------- //

    [EditPasswordUserAdmin.pending]: (state) => {
      state.loading = true;
    },

    [EditPasswordUserAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    },

    [EditPasswordUserAdmin.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ----------------- //

    [deleteUser.pending]: (state) => {
      state.loading = true;
    },

    [deleteUser.fulfilled]: (state) => {
      state.loading = false;
      state.user.active = false;
    },

    [deleteUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // ----------------- //

    [deleteAdmin.pending]: (state) => {
      state.loading = true;
    },

    [deleteAdmin.fulfilled]: (state) => {
      state.loading = false;
      state.user.active = false;
    },

    [deleteAdmin.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = usersListSlice.actions;

export default usersListSlice.reducer;
