// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  user: icon('ic_user'),
  dinner: icon('dinner-svgrepo-com'),
  content: icon('ic_file'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      // { title: 'Dashboard', path: PATH_DASHBOARD.dashboard, icon: ICONS.dashboard },

      {
        title: 'Users',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [{ title: 'Users', path: PATH_DASHBOARD.user.users }],
      },
      {
        title: 'Dinner',
        path: PATH_DASHBOARD.dinner.root,
        icon: ICONS.dinner,
        children: [
          { title: 'Main Dishes', path: PATH_DASHBOARD.dinner.dinner },
          { title: 'Side Dishes', path: PATH_DASHBOARD.dinner.sideDishe },
          { title: 'Dinner Schedule', path: PATH_DASHBOARD.dinner.dinnerSchedhule },

          { title: 'Reservations', path: PATH_DASHBOARD.dinner.reservation },
          { title: 'Ratings', path: PATH_DASHBOARD.dinner.rating },
        ],
      },
    ],
  },
];

export default navConfig;
