/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDinnerAccountGeneral } from 'src/@types/dinner';

import axios from '../../../utils/axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

interface UserType {
  dinner: IDinnerAccountGeneral;

  sideDishe: any[];
  loading: boolean;
  isFetchStudentsLoading: 'idle' | 'loading' | 'success' | 'failed';
  error: string;
  meta: object | null;
  nestedSideDishe: any[];
  hasMores:boolean

}

const initialState: UserType = {
  dinner: {
    name: '',

description:''




  },

  isFetchStudentsLoading: 'idle',
  nestedSideDishe:[],
  meta: null,
  hasMores:true,

  sideDishe: [],
  loading: false,
  error: '',
};


// eslint-disable-next-line consistent-return
export const fetchsideDishe: any = createAsyncThunk('dinner/fetchSideDishe', async (params) => {
  try {
    const response = await axios.get(`${baseUrl}/sideDishe`, {
      params,
    });
    return response?.data;
  } catch (error) {
    return error;
  }
});

// eslint-disable-next-line consistent-return
export const getsideDisheById: any = createAsyncThunk(
  'dinner/getDinnerById',
  // eslint-disable-next-line consistent-return
  async ({ _id }: any) => {
    try {
      const response = await axios.get(`${baseUrl}/sideDishe/${_id}`);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);



export const createNewsideDishe: any = createAsyncThunk(
  'dinner/createDinner',
  // eslint-disable-next-line consistent-return
  async (data: IDinnerAccountGeneral) => {
    try {
      const response = await axios.post(`${baseUrl}/sideDishe`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const updatesideDishe: any = createAsyncThunk(
  'dinner/updateDinner',
  // eslint-disable-next-line consistent-return
  async ({ id, data }: { id: string; data: IDinnerAccountGeneral }, { rejectWithValue }) => {
  
    try {
      const response = await axios.put(
        `${baseUrl}/sideDishe/${id}`,
        { ...data },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
   

      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);










// eslint-disable-next-line consistent-return
export const deletesideDishe: any = createAsyncThunk('users/deleteAdmin', async (_id) => {
  try {
    const response = await axios.delete(`${baseUrl}/sideDishe/${_id}`);
    return response.data;
  } catch (error) {
    return error;
  }
});

export const sideDisheListSlice: any = createSlice({
  name: 'SideDishe',
  initialState,
  reducers: {},
  extraReducers: {

    // --------- //

    // --------------- //

    
    // -------------- //

    [fetchsideDishe.pending]: (state) => {
      state.loading = true;
    },

    [fetchsideDishe.fulfilled]: (state, { payload }) => {
      state.loading = false;

      state.sideDishe = payload.data?.docs;
      state.meta = payload.data.totalPages;
      state.hasMores=payload.data.hasMore;
      
      if (
        payload?.data?.totalDocs !== undefined &&
        state?.nestedSideDishe?.length !== undefined &&
        payload.data.totalDocs > state.nestedSideDishe.length
      ) {
        state.nestedSideDishe = [...state.nestedSideDishe, ...(payload.data?.docs || [])];
      }
      
     
    },

    [fetchsideDishe.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ---------------- //

    [getsideDisheById.pending]: (state) => {
      state.loading = true;
    },

    [getsideDisheById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dinner = payload.data;
    },

    [getsideDisheById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ----------------- //

    [createNewsideDishe.pending]: (state) => {
      state.loading = true;
    },

    [createNewsideDishe.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dinner = payload.data;
    },

    [createNewsideDishe.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ----------------- //

   

    // -------------------- //

    [updatesideDishe.pending]: (state) => {
      state.loading = true;
    },

    [updatesideDishe.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dinner = payload.data;
    },

    [updatesideDishe.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      
    },

    // -------------------- //



    // -------------------- //

   

    // ----------------- //

    [deletesideDishe.pending]: (state) => {
      state.loading = true;
    },

    [deletesideDishe.fulfilled]: (state) => {
      state.loading = false;

    },

    [deletesideDishe.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // ----------------- //


  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = sideDisheListSlice.actions;

export default sideDisheListSlice.reducer;
