/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { useNavigate } from 'react-router';
import { IUserAccountGeneral } from 'src/@types/user';
import { useSnackbar } from 'src/components/snackbar';
import axios from '../../../utils/axios';

// import { tr } from 'date-fns/locale';
import { PATH_DASHBOARD } from '../../../routes/paths';

const baseUrl = process.env.REACT_APP_BASE_URL;

interface UserType {
  statistic: [];
  loading: boolean;
  error: string;
}

const initialState: UserType = {
  statistic: [],
  loading: false,
  error: '',
};

// eslint-disable-next-line consistent-return
export const fetchDashboardDetails: any = createAsyncThunk(
  'type/fetchDashboardDetails',
  async () => {
    try {
      const response = await axios.get(`${baseUrl}/statistic`);
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const dashboardSlice: any = createSlice({
  name: 'Users',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDashboardDetails.pending]: (state) => {
      state.loading = true;
    },

    [fetchDashboardDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.statistic = payload;
    },

    [fetchDashboardDetails.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
