/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IDinnerAccountGeneral } from 'src/@types/dinner';

import axios from '../../../utils/axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

interface rateType {
  userReservation: any;

  reservation:any;
 
  loading: boolean;
  isFetchStudentsLoading: 'idle' | 'loading' | 'success' | 'failed';
  error: string;
  meta: object | null;
}

const initialState: rateType = {
  userReservation: {
  




  },

  isFetchStudentsLoading: 'idle',

  meta: null,

  reservation: [],

  loading: false,
  error: '',
};


// eslint-disable-next-line consistent-return
export const fetchReservation: any = createAsyncThunk('reservation/fetchReservation', async (params) => {
  try {
    const response = await axios.get(`${baseUrl}/dinnerReservation`, {
      params,
    });
  
    
    return response.data;
  } catch (error) {
    return error;
  }
});

// eslint-disable-next-line consistent-return

export const fetchUserReservation: any = createAsyncThunk(
    'reservation/fetchUserReservation',
    // eslint-disable-next-line consistent-return
    async ({id}: any) => {
      try {
        const response = await axios.get(`${baseUrl}/dinnerReservation/${id}`);
   
        
        return response?.data;
      } catch (error) {
        return error;
      }
    }
  );
  














// eslint-disable-next-line consistent-return


export const reservationListSlice: any = createSlice({
  name: 'Reservation',
  initialState,
  reducers: {},
  extraReducers: {

    // --------- //

    // --------------- //

    
    // -------------- //

    [fetchReservation.pending]: (state) => {
      state.loading = true;
    },

    [fetchReservation.fulfilled]: (state, { payload }) => {
      state.loading = false;


      state.reservation = payload.data?.docs;
      state.meta = payload.data.meta;

     
    },

    [fetchReservation.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ---------------- //



    // ----------------- //

    [fetchUserReservation.pending]: (state) => {
      state.loading = true;
    },

    [fetchUserReservation.fulfilled]: (state, { payload }) => {
      state.loading = false;
    
      
      state.userReservation = payload.data;
      state.meta = payload.meta;
    },

    [fetchUserReservation.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ----------------- //

   

    // -------------------- //

    

    // -------------------- //



    // -------------------- //

   

    // ----------------- //

   

    // ----------------- //


  },
});

// eslint-disable-next-line no-empty-pattern
export const { } =  reservationListSlice.actions;

export default  reservationListSlice.reducer;
