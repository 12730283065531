import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  Dashboard,
  Admin,
  LoginPage,

  SideDishe, 
  NewPasswordPage,
  NewUser,
 
  EditUser,

  NewAdmin,
  EditAdmin,

  UpdateAdmin,
  UpdateProfile,
  AdminDetails,
Dinner, NewDinner,
EditDinner,SideDisheDetails,
NewSideDishe,
EditSideDishe,
DinnerDetails,
DinnerScheduhle,
Reservations,
Rating
 



} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN || 'login'}  />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },

        {
          element: <CompactLayout />,
          children: [
          
            { path: 'new-password', element: <NewPasswordPage /> },
          ],
        },
      ],
    },
    {
      path: 'profile',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/profile" replace />, index: true },
        { path: 'update', element: <UpdateAdmin /> },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),

      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        { path: 'dashboard', element: <Dashboard /> },
        { path: 'editProfile', element: <UpdateProfile /> },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/users" replace />, index: true },
            { path: 'users', element: <Admin /> },
            { path: ':_id/adminDetails', element: <AdminDetails /> },
            { path: 'newAdmin', element: <NewAdmin /> },
            { path: ':_id/editAdmin', element: <EditAdmin /> },
           
          
          
          ],
        },

        {
          path: 'dinner',
          children: [
            { element: <Navigate to="/dashboard/dinner/dinner" replace />, index: true },
            { path: 'dinner', element: <Dinner /> },
            { path: 'sideDishe', element: <SideDishe /> },
            { path: ':_id/dinnerDetails', element: <DinnerDetails /> },
            { path: 'newDinner', element: <NewDinner /> },
            { path: ':_id/sideDisheDinner', element: <EditSideDishe /> },
            { path: ':_id/sideDisheDetails', element: <SideDisheDetails /> },
            { path: 'newsideDishe', element: <NewSideDishe /> },
            { path: ':_id/editSideDishe', element: <EditSideDishe /> },
            { path: ':_id/editDinner', element: <EditDinner/> },
            { path: 'dinnerSchedhule', element: <DinnerScheduhle /> },
            { path: 'rating', element: <Rating /> },
            { path: 'reservation', element: <Reservations /> },

          ],
        },
     
         
       
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
