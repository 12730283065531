
function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  // resetPassword: path(ROOTS_AUTH, '/reset-password'),
  // newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
  editProfile: path(ROOTS_DASHBOARD, '/profile/update'),

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    admin: path(ROOTS_DASHBOARD, '/user/admin'),
    adminDetails: (_id: string) => path(ROOTS_DASHBOARD, `/user/${_id}/adminDetails`),
    newAdmin: path(ROOTS_DASHBOARD, '/user/newAdmin'),
    editAdmin: (_id: string) => path(ROOTS_DASHBOARD, `/user/${_id}/editAdmin`),
    users: path(ROOTS_DASHBOARD, '/user/users'),
    userDetails: (_id: string) => path(ROOTS_DASHBOARD, `/user/${_id}/userDetails`),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    edit: (_id: string) => path(ROOTS_DASHBOARD, `/user/${_id}/edit`),
  },

  dinner: {
    root: path(ROOTS_DASHBOARD, '/dinner'),
    dinner: path(ROOTS_DASHBOARD, '/dinner/dinner'),
    dinnerDetails: (_id: string) => path(ROOTS_DASHBOARD, `/dinner/${_id}/dinnerDetails`),
    newDinner: path(ROOTS_DASHBOARD, '/dinner/newDinner'),
    editDinner: (_id: string) => path(ROOTS_DASHBOARD, `/dinner/${_id}/editDinner`),
    sideDishe: path(ROOTS_DASHBOARD, '/dinner/sideDishe'),
    sideDisheDetails: (_id: string) => path(ROOTS_DASHBOARD, `/dinner/${_id}/SideDisheDetails`),
    newsideDishe: path(ROOTS_DASHBOARD, '/dinner/newSideDishe'),
    editsideDishe: (_id: string) => path(ROOTS_DASHBOARD, `/dinner/${_id}/editSideDishe`),
   dinnerSchedhule:path(ROOTS_DASHBOARD, '/dinner/dinnerSchedhule'),
   rating:path(ROOTS_DASHBOARD, '/dinner/rating'),
   reservation:path(ROOTS_DASHBOARD, '/dinner/reservation'),
  },
  
  
 
 
 
};
