import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import UsersReducers from './slices/users/usersSlice';
import DinnerReducers from './slices/dinner/dinnerSlice';
import calendarReducer from './slices/scheduhle/dinnerSchedule';
import ratingReducer from './slices/reviews/reviews';
import reservationReducer from './slices/reservation/reservationSlice';
import dashboardReducer from './slices/dashboard/dashboardSlice';
import SideDisheReducers from './slices/dinner/sideDisheSlice';

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  Users: UsersReducers,
  Dinner: DinnerReducers,
  calendar: calendarReducer,
  dashboard: dashboardReducer,
Rate:ratingReducer,
Reservation:reservationReducer,
SideDishe:SideDisheReducers
});

export default rootReducer;
