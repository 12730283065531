import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
// @types
import { ICalendarState, ICalendarEvent } from '../../../@types/calender';

// ----------------------------------------------------------------------

const initialState: ICalendarState = {
  isLoading: false,
  error: null,
  events: [],
 
};

const baseUrl = process.env.REACT_APP_BASE_URL;


// ----------------------------------------------------------------------


export const getEvents: any = createAsyncThunk(
  'events/getEvents',
  async (payload: { params?: any }) => {
    try {
      // Construct the URL with the 'month' and 'year' parameters
      const response = await axios.get(`${baseUrl}/dinnerSchedule/PerMonth`, {
        params: {
          month: payload?.params?.month, // Replace with your desired month value
          year: payload?.params?.year,     // Replace with your desired year value
        },
      });
   

      return response?.data?.data;
      
    } catch (error) {
      return error;
    }
  }
);


export const createEvent: any = createAsyncThunk(
  'events/createEvent',

  async (data: any) => {

    const response = await axios.post(
      '/dinnerSchedule',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;


  }
);

// ----------------------------------------------------------------------

export const updateEvent: any = createAsyncThunk(
  'events/updateEvent', // Use a more appropriate action type string
  async (data: { id: string; dishes: any, date: string }, { rejectWithValue }) => {

    try {
      const response = await axios.put(
        `/dinnerSchedule/${data?.id}`,
        { ...data },
        {
          headers: {
            'Content-Type': 'application/json', // Use application/json for JSON data
          },
        }
      );
      return response.data;
      
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

// ----------------------------------------------------------------------

// 
// eslint-disable-next-line consistent-return
export const deleteEvent: any = createAsyncThunk('dinner/dinnerSchedule', async (_id) => {
  try {
    return await axios.delete(`dinnerSchedule/${_id}`);
  } catch (error) {
    return error;
  }
});
const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = true;
      state.events = action.payload.data;
    },

    // CREATE EVENT


    // UPDATE EVENT
    updateEventSuccess(state, action) {
      state.isLoading = false;
      state.events = state.events.map((event) => {
        if (event.id === action.payload.data.id) {
          return action.payload.data;
        }
        return event;
      });
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const eventId = action.payload.data;
      state.events = state.events.filter((event) => event.id !== eventId);
    },
  },
  extraReducers: {
    [getEvents.pending]: (state) => {
      state.isLoading = true;
    },

    [getEvents.fulfilled]: (state, action) => {
   

      state.isLoading = false;
      state.events = action.payload;





    },

    [getEvents.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    },
    [createEvent.pending]: (state) => {
      state.isLoading = true;
    },

    [createEvent.fulfilled]: (state, action) => {
      const newEvent = action.payload;

      state.isLoading = false;
      state.events = [...state.events, newEvent];





    },

    [createEvent.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    },
   
    [updateEvent.pending]: (state) => {
      state.isLoading = true;
    },

    [updateEvent.fulfilled]: (state, action) => {
      const newEvent = action.payload.data;


      state.isLoading = false;
      state.events = [...state.events, newEvent];





    },

    [updateEvent.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.error = error;
    },

    [deleteEvent.pending]: (state) => {

      state.isLoading = true;
    },

    [deleteEvent.fulfilled]: (state, action) => {







    },
    [deleteEvent.rejected]: (state, { error }) => {
      state.isLoading = false;
      
      state.error = error;
    },
   

  }

});



export default slice.reducer;
